<script setup>
const backend = useBackend();

const refSlideControl = ref();
const refSlideNavigation = ref();

const {data: blocks} = await useAsyncData('blocks',async () => {
  const result = await backend.getModelDescriptionCards();
  if(!result?.data || !result.data.length) return [];
  console.log(result.data);
  // return result.data;
  return result.data.sort((a, b) => a.is_first ? -1 : 1);
})

const updateSlideNumber = (slideNumber) => {
  refSlideNavigation.value.setSlide(slideNumber);
}

const scrollToSlide = (slide) => {
  refSlideControl.value.scrollToSlide(slide);
}

</script>

<template>
  <Container gray>
    <div v-if="blocks.length">
      <div class="flex justify-between items-center">
        <SubContentHeader>Wybrane oferty dnia</SubContentHeader>

      </div>
      <SlideControl ref="refSlideControl"
                    class="-mt-4"
                    @swipeEnd="(slideNumber) => updateSlideNumber(slideNumber)"
      >
        <template v-for="block in blocks">
          <SlideItem :custom-size>
           <CarDayOfferCard :data="block" />
          </SlideItem>
        </template>
      </SlideControl>
      <SlideNavigation ref="refSlideNavigation"
                       class="-mt-4"
                       :items=blocks?.length
                       @changed="(slide) => scrollToSlide(slide)"
      />

    </div>
  </Container>

</template>
