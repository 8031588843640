<script setup>

</script>

<template>
  <div class="group relative flex flex-col w-full min-w-[300px] h-[300px]
              rounded-lg shadow-box overflow-hidden select-none
              transition-all duration-300 animate-pulse bg-white">
  </div>
</template>
