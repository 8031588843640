<script setup>

const analytics = useAnalytics();
const isDragging = useState('slideControlDragging', () => false)

const props = defineProps({
  data: Object
})

const photos = computed(() => props.data.mainPhotos);
const url = computed(() => ({
  name: 'model',
  params: {
    brand: toLowerDashed(props.data.brandName),
    model: toLowerDashed(props.data.modelName),
  }
}))

const serverDate = await getServerDate();

const sendAnalytics = () => {
  analytics.analyticsLayer({
    'event': 'click_oferta_dnia',
    'type': 'home_section_2',
    'creative_name': 'Oferta dnia - ' + props.data.brandName + ' ' + props.data.modelName,
    'link_url': '/samochody/'+toLowerDashed(props.data.brandName)+"/"+toLowerDashed(props.data.modelName),
  });
}

const banner = computed(() => {
  const now = (new Date);
  if (!photos.value || !photos.value.length) return "";

  let currentPhoto = "";
  photos.value.forEach(photo => {
    if (photo.date_from && new Date(photo.date_from) > serverDate) return;
    if (photo.date_to && new Date(photo.date_to) < serverDate) return;
    currentPhoto = photo.url;
  })

  return currentPhoto;
})
</script>

<template>
  <NuxtLinkConditional :can="!isDragging" :to="url"
            @dragstart.prevent=""
            class="group relative flex flex-col w-full min-w-[300px] h-[450px]
              rounded-lg shadow-box hover:shadow-box-hover overflow-hidden select-none
              transition-all duration-300 hover:-translate-y-1" @click="sendAnalytics">
    <div class="w-full h-[402px] relative bg-center bg-cover" :style="{'background-image': 'url('+banner+')'}">

    </div>
    <div class="w-full h-12 bg-azure px-3 py-4 text-white text-center leading-none font-light cursor-pointer">
      Poznaj model
    </div>
  </NuxtLinkConditional>
</template>
