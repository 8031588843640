<script setup>

import sportage from '@/assets/images/wall/WWW_500x300_sportage.jpg';
import qashqai from '@/assets/images/wall/WWW_500x300_qashqai.jpg';
import a4 from 'assets/images/wall/WWW_500x300_audi_a4_sedan.jpg';
import master from '@/assets/images/wall/WWW_500x300_nowy_master.jpg';
import mokka from '@/assets/images/wall/WWW_500x300_mokka.jpg';
import tucson from '@/assets/images/wall/WWW_500x300_tucson.jpg';
import { useCalculator } from "~/composables/Calculator";

const cars = ref({data: []});
const title = ref('Hity sierpnia')
const carsFound = ref(false);

const calculator = useCalculator();

const carDefs = ref([
  {brand: 'KIA', model: 'Sportage', body: 'SUV', url: '/wybierz/kia/sportage', img: sportage},
  {brand: 'Nissan', model: 'Qashqai', body: 'SUV', url: '/wybierz/nissan/qashqai', img: qashqai},
  {brand: 'Audi', model: 'A4', body: 'sedan', url: '/wybierz/audi/a4', img: a4},
  {brand: 'Renault', model: 'Master', body: 'Furgon', url: '/wybierz/renault/master', img: master},
  {brand: 'Opel', model: 'Mokka', body: 'hatchback', url: '/wybierz/opel/mokka', img: mokka},
  {brand: 'Hyundai', model: 'Tucson', body: 'SUV', url: '/wybierz/hyundai/tucson', img: tucson},
]);

onMounted(async () => {
  const tmpCars = [];
  for(let i in carDefs.value) {
    const carDef = carDefs.value[i];

    const car = await useBackend().getModels({
      model: carDef.model,
      bodyType: carDef.body
    });

    const found = car.data.find(i => i.count > 0);
    // cars.value.data.push(found);
    tmpCars.push(found)
  }

  console.log('🟢', tmpCars);

  fisherYates(tmpCars);
  carsFound.value = true

  cars.value.data = tmpCars.filter(i => i);
  calculator.getInstallment(cars.value);
})

</script>

<template>
  <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 -mb-6">
    <div class="py-6 text-center md:text-left">
      <h3 class="text-2xl inline leading-7" >
        {{title}}
      </h3>
      <span class="ml-1 font-extralight text-lg">(6)</span>
    </div>
  </div>
  <template v-if="carsFound">
    <template v-for="(car,index) in cars.data">
      <CarTile :car="car" :bg="index===0 ? carDefs.find(i => i.model === car.modelName) : null" />
    </template>
  </template>
  <template v-else>
    <template v-for="index in 6">
      <SkeletonCarTile />
    </template>
  </template>
</template>
