<script setup>

useHead({
  title: "Vehis.pl - atrakcyjny leasing samochodów nowych i używanych",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl'}
  ]
});

const calculator = useCalculator();

const {data: cars} = await useAsyncData(() => new Promise(async resolve => {
  const models = await useBackend().getModels({promoted: 1});
  console.log('models promoted', models);
  await calculator.getInstallment(models);
  resolve(models)
}))

</script>

<template>
  <Container no-paddings>
    <AppTop />
    <div>
      <LayoutColumns>
        <LayoutMainCarsColumn class="pb-4 mb-14">
          <CarWall />
          <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 -mb-6">
            <MainContentHeader :counter="cars.data.length + 1">Leasing samochodu w VEHIS - najpopularniejsze modele</MainContentHeader>
          </div>
          <BannerTile />
          <template v-for="car in cars?.data">
            <CarTile :car="car" />
          </template>
        </LayoutMainCarsColumn>
        <LayoutSideColumn class="mt-20 pb-4 !bg-transparent">
          <FormContact />
          <div class="h-4"></div>
        </LayoutSideColumn>
      </LayoutColumns>
    </div>
  </Container>

  <CarDayOffers />

 <CarCollections />

  <Container>
    <SeoMoreAboutModels :cars="cars.data" title="Oferta tygodnia:" />
  </Container>

  <Container with-border-top>
    <BlockBestFeaturesCompared />
  </Container>

  <Container class="pt-8 pb-4" no-paddings with-border-top>
    <BlockHowTo />
  </Container>

  <Container class="pt-8 pb-12"no-paddings with-border-top >
    <SeoHomePage />
  </Container>

  <Container class="pt-10 pb-6" no-paddings gray>
    <BlockFeaturesComparedText />
  </Container>

  <Container>
    <FormContact title="W każdym momencie możesz skorzystać z&nbsp;pomocy naszych Doradców" standalone />
  </Container>

  <Container with-border-top>
    <SeoBrandsCloud />
  </Container>

</template>
