<script setup>

const backend = useBackend();
const { data: collections } = await useAsyncData(async () => {
  const collections = await backend.getCollections();
  return collections.data || [];
});

const modifiedOrderCollections =  collections.value.length > 1 ? [collections.value[collections.value.length - 1], ...collections.value.slice(1, -1), collections.value[0]] : collections.value;

</script>

<template>
  <template v-for="(collection,index) in modifiedOrderCollections">
    <Container :gray="!!(index % 2)">
      <CarCollection :title="collection.title"
                     :search="collection.search"
                     :href="collection.slug"
                     limit="12"
      />
    </Container>
  </template>
</template>
